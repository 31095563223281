import React, { useState, useEffect, useRef } from "react";
import { REACT_APP_GOOGLE_API_KEY } from "../../env";
import { getI18n } from "react-i18next";

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(
  updateQuery,
  updateLat,
  updateLng,
  handleCoordonee,
  autoCompleteRef
) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    //{ types: ["(cities)"], componentRestrictions: { country: "fr" } }
    { componentRestrictions: { country: "fr" } }
  );

  autoComplete.setFields([
    "address_components",
    "formatted_address",
    "geometry",
  ]);
  autoComplete.addListener("place_changed", () => {
    handlePlaceSelect(updateQuery, updateLat, updateLng, handleCoordonee);
  });
}

async function handlePlaceSelect(
  updateQuery,
  updateLat,
  updateLng,
  handleCoordonee
) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  const lat = addressObject.geometry.location.lat();
  const lng = addressObject.geometry.location.lng();
  updateQuery(query);
  updateLat(lat);
  updateLng(lng);
  handleCoordonee(query, lat, lng);

  console.log(addressObject);
  console.log(addressObject.geometry.location.lat());
  console.log(addressObject.geometry.location.lng());
}

function SearchLocationInput(props) {
  const [query, setQuery] = useState("");
  const [value, setValue] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const _ = getI18n();

  const autoCompleteRef = useRef(null);
  useEffect(() => {
        handleScriptLoad(
          setQuery,
          setLat,
          setLng,
          props.handleCoordonee,
          autoCompleteRef
        )
  }, [props.handleCoordonee]);
  return (
    <div className="search-location-input">
      <input
        className="form-control"
        ref={autoCompleteRef}
        onChange={(event) => setQuery(event.target.value)}
        placeholder={_.t("LOCATION")}
        value={query}
      />
    </div>
  );
}

export default SearchLocationInput;
