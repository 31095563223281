import React, { useState, useRef } from "react";
import { Map, GoogleApiWrapper, Marker, Circle } from "google-maps-react";
import Geocode from "react-geocode";

const mapStyles = {
  width: "100%",
  height: "480px",
};

const containerStyle = {
  position: "relative",
  width: "100%",
  height: "480px",
};
const MapComponent = (props) => {
  console.log('[MAP] - ', props);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [distance, setDistance] = useState(props.distance);
  React.useEffect(() => {
    console.log('Mapping: ', props);
    if (props.lat) {
      setLat(props.lat);
    }
    if (props.lng) {
      setLng(props.lng);
    }
    if (props.distance) {
      setDistance(props.distance);
    }

  }, [props]);
  const onMarkerDragEnd = async (coord, index) => {
    Geocode.setApiKey('AIzaSyCDJ_8QPJUFbK_v_CAeY3J1T9ehvMoJq2A');
    Geocode.setLanguage("fr");
    Geocode.enableDebug();

    //this.props.setLocation(coord);
    const { latLng } = coord;
    let formatedAdress = "";
    await Geocode.fromLatLng(latLng.lat(), latLng.lng()).then(
      (response) => {
        console.log('Response: ', response);
        const address = response.results[0].formatted_address;
        console.log('Address: ', address);
        formatedAdress = address;
      },
      (error) => {
        console.error('eeer', error);
      }
    );
    setLat(latLng.lat());
    setLng(latLng.lng());
    props.setLocation(latLng.lat(), latLng.lng(), formatedAdress);
  }
  return (<Map
    google={window.google}
    zoom={props.zoom ? props.zoom : 9}
    style={mapStyles}
    containerStyle={containerStyle}
    initialCenter={{
      lat: props.lat,
      lng: props.lng ,
    }}
    center={{
      lat: lat,
      lng: lng,
    }}
  >

<Marker
          id={1}
           position={{
             lat: lat || 1, lng: lng || 1
           }}
           draggable={props.drag ? true : false}
           onDragend={(t, map, coord) => onMarkerDragEnd(coord, '')}
         ></Marker>
      <Circle
        radius={(distance || 1) * 1000}
        center={{
          lat: Number(lat) || 1,
          lng: Number(lng) || 1,
        }}
        strokeColor="transparent"
        strokeOpacity={0}
        strokeWeight={5}
        fillColor="#FF0000"
        fillOpacity={0.2}
      />
  </Map>);
}

export default MapComponent;

